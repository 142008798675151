import React from 'react';
import { Outlet } from "react-router-dom";
import './layout.css';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import Constantes from '../comun/Constantes';
import { Button } from 'primereact/button';

const Layout = () => {  
    const navigate = useNavigate();
    let carritotmp
    if(localStorage.getItem(Constantes.LOCALSHOPCAR)!==null){
        carritotmp = JSON.parse(localStorage.getItem(Constantes.LOCALSHOPCAR))
    }
    
    const nomostrar = !window.location.pathname.includes('checkout') && !window.location.pathname.includes('orden')
    
    return (
        <div className='p-3 mt-2'>
            {nomostrar && <div className='text-end pb-2'>
                <Button icon="pi pi-cart-arrow-down" rounded text aria-label="Filter" onClick={() => navigate('checkout')}>
                    <Badge value={carritotmp?Object.keys(carritotmp).length:0}></Badge>
                </Button>
            </div>}
            <Outlet />
        </div>
    )
}

export default Layout 