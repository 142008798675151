const Constantes = {
    NOMBRECKUSUARIO: 'dfalk140',
    LOCALUSUARIO: 'usuariodumpster',
    ADMINUSUARIO: 'admindumpster',
    TOKENADMINUSUARIO: 'atkdm',
    LOCALSHOPCAR: 'shopcardst',
    LOCALPAGO: 'shoppagodst',
    LOCALCLIENTE: 'shoppcliente',
    estRegistro: [
        {label: 'Activo', value: 'ACT'},
        {label: 'Inactivo', value: 'INA'}
    ],
    estadoPago: ['PAGADA','PENDIENTE','ABONADA','DEVUELTA'],
    planes:{
        ILIMITADO: 'I',
        MARKET:'M'
    },
    tipousuario:{
        ADMINISTRADOR: 'A',
        SUCURSAL:'S',
        USUARIO:'U'
    },
    tiposervicio:[
        'Drop Off & Swap',
        'Pick Up'
    ],
    tiporecoleccion:[
        'Garbage (C&D)',
        'Recycling (Scrap Metal)'
    ]
}

export default Constantes;